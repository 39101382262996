import { LIST_APPOINTMENTS } from '@/data/actions/modules/clinical';

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "appointments" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_APPOINTMENTS,
    },
  },
];
