import { vuex as auth } from './auth';
import { vuex as appointment } from './appointment';

const vuex = {
  auth, appointment,
};
const keys = Object.keys(vuex);

const modules = keys.reduce((acc, key) => ({ ...acc, [key]: vuex[key].module }), {});
const plugins = keys.reduce((acc, key) => [...acc, vuex[key].plugin], []);

export default { modules, plugins };
