<template>
  <div id="app" class="master-layout" :class="conditionalClasses">
    <div class="notification-bar" v-if="expiresNotification">
      {{ expiresNotification }}
    </div>
    <header class="navbar main-nav">
      <section class="navbar-section">
        <div class="columns">
          <div class="column col-auto">
            <router-link to="/" tag="section" class="c-hand navbar-section">
              <img src="../assets/svg/logo-stenci.svg" alt="Stenci" class="logo">
            </router-link>
          </div>
          <div class="divider-vert"></div>
          <div class="column">
            <div class="page-name">
              <portal-target name="page-name"></portal-target>
            </div>
          </div>
        </div>
      </section>
      <section class="navbar-section">
<!--        <button class="btn btn-icon btn-link btn-menu-action"-->
<!--                @click="expenseModalShow = true">-->
<!--          <fa-icon :icon="['fal', 'search']"/>-->
<!--        </button>-->
        <button
          @click="toggleChat"
          class="btn btn-icon btn-link btn-chat"
          :class="{ badge: chatUnread }">
          <fa-icon :icon="['fal', 'comment']"/>
        </button>
        <div class="divider-vert"></div>
        <div class="tile tile-centered user-menu" @click="toggleUserMenu">
          <div class="tile-icon">
            <figure class="avatar">
              <img :src="userImage" :alt="user.name">
            </figure>
          </div>
          <div class="tile-content">
            <div class="user-name">{{ name }}</div>
            <div class="user-branch">{{ user.branch ? user.branch.name : '-' }}</div>
          </div>
          <div class="tile-action">
            <fa-icon :icon="['fas', 'caret-down']"/>
          </div>
        </div>
        <ul class="menu user-menu-list" v-if="userMenu.show" @click.stop>
          <li class="menu-item">
            <router-link to="/me">
              <fa-icon :icon="['fal', 'user-circle']"/>
              <span class="side-menu-label ml-2">Meu Perfil</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link to="/" @click.native.prevent="branch.show = true">
              <fa-icon :icon="['fal', 'building']"/>
              <span class="side-menu-label ml-2">Trocar de Unidade</span>
            </router-link>
          </li>
          <li class="divider"/>
          <li class="menu-item">
            <router-link to="/logout">
              <fa-icon :icon="['fal', 'sign-out-alt']"/>
              <span class="side-menu-label ml-2">Sair</span>
            </router-link>
          </li>
        </ul>
      </section>
    </header>
    <section class="primary">
      <main class="main">
        <router-view v-if="canAccess" />
        <expired-state v-else-if="branchIsExpired"></expired-state>
        <forbidden-state v-else>
          <button
            class="btn btn-primary btn-icon btn-icon-left"
            @click="$router.back()"
          >
            <fa-icon :icon="['fal', 'chevron-left']"></fa-icon>
            <span>Voltar</span>
          </button>
        </forbidden-state>
      </main>
    </section>

    <dx-modal
      v-model="branch.show"
      title="Selecione uma empresa"
      id="branch-modal"
      :closable="isSetBranch">
      <div class="form-group" v-if="user.branches.length > 4">
        <div class="input-group pb-2">
          <input
            type="text"
            class="form-input input-lg"
            v-model="branch.search"
            placeholder="Pesquisar..."
            v-focus
          >
          <button
            class="btn btn-lg btn-icon btn-action btn-gray-outline input-group-btn"
          ><fa-icon :icon="['fal', 'search']"></fa-icon></button>
        </div>
      </div>

      <div class="branches">
        <div
          v-for="branch in availableBranches"
          :key="branch.id"
          @click="switchBranch(branch.id)"
          class="tile c-hand"
          :class="{ active: user.branch && user.branch.id === branch.id }">
          <div class="tile-icon">
            <figure class="avatar" :data-initial="$avatarLetters(branch.name)"></figure>
          </div>
          <div class="tile-content">
            <div class="tile-title">{{ branch.name }}</div>
            <div class="tile-subtitle">{{ branch.address | address }}</div>
          </div>
        </div>
      </div>
    </dx-modal>

    <expense-modal-pricing
      v-if="expenseModalShow"
      :show="expenseModalShow"
      @close="expenseModalShow = false"
    />

    <chat v-if="isSetBranch" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Chat from '@/components/chat/Chat.vue';
import ForbiddenState from '@/components/auth/Forbidden.vue';
import ExpiredState from '@/components/auth/Expired.vue';
import ExpenseModalPricing from './modals/PricingModal.vue';

export default {
  data() {
    return {
      expenseModalShow: false,
      userMenu: {
        show: false,
      },
      branch: {
        show: false,
        changing: false,
        search: '',
      },
      modules: {
        show: false,
      },
    };
  },
  components: {
    Chat,
    ExpenseModalPricing,
    ForbiddenState,
    ExpiredState,
  },
  mounted() {
    if (!this.isSetBranch) {
      this.branch.show = true;
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...mapGetters({
      chatUnread: 'chatUnread',
      currentModule: 'currentModule',
      branchIsExpired: 'branchIsExpired',
      expiresNotification: 'expiresNotification',
    }),
    conditionalClasses() {
      return {
        'chat-active': this.$store.state.chat.show,
        'has-notification': this.expiresNotification,
      };
    },
    availableBranches() {
      const search = this.branch.search.trim();
      if (search) {
        return this.user.branches.filter(({ name }) => (
          new RegExp(search, 'i').test(name)
        ));
      }
      return this.user.branches;
    },
    isSetBranch() {
      return this.user && this.user.branch !== null;
    },
    name() {
      return this.user
        ? this.user.name.split(' ').slice(0, 3).join(' ')
        : '';
    },
    canAccess() {
      if (this.branchIsExpired) {
        return false;
      }
      if ('meta' in this.$route && 'permission' in this.$route.meta) {
        return this.$can(this.$route.meta.permission);
      }
      return true;
    },
    userImage() {
      return this.user.imageUrl
        ? `${this.user.imageUrl}?w=200&h=200`
        : `//static.stenci.pro/images/no-image-${this.user.gender}.png`;
    },
  },
  methods: {
    ...mapMutations({
      toggleChat: 'Chat.TOGGLE',
    }),
    switchBranch(id) {
      this.branch.changing = true;
      this.$http.post('/me/branch', {
        branchId: id,
      })
        .then(({ data }) => {
          this.$store.dispatch('setUser', data.user);
          this.$store.dispatch('setToken', data.token);
          this.$store.dispatch('setModule', 'clinical');
        })
        .catch(() => {})
        .then(() => {
          setTimeout(() => {
            this.branch.changing = false;
            this.branch.show = false;
            this.$nextTick(() => {
              document.location.reload();
            });
          }, 300);
        });
    },
    toggleUserMenu() {
      if (this.userMenu.show) {
        this.closeUserMenu();
      } else {
        this.showUserMenu();
      }
    },
    showUserMenu() {
      this.userMenu.show = true;
      setTimeout(() => document
        .addEventListener('click', this.closeUserMenu), 1);
    },
    closeUserMenu() {
      this.userMenu.show = false;
      document.removeEventListener('click', this.closeUserMenu);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import "./src/assets/scss/_colors.scss";

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$header-height: 3.6rem;
$notification-bar-height: 1.6rem;
$sidebar-width: 5.5rem;
.master-layout {
  background-color: #F6F9FA;
  display: grid;
  grid-template-rows: $header-height auto;
  height: 100vh;

  &.has-notification {
    grid-template-rows: $notification-bar-height $header-height auto;
  }

  .page-name {
    font-size: $font-size;
    font-weight: 500;
  }
  .module-name {
    color: $info-color;
    cursor: pointer;
    font-size: $font-size-xs;
    &:hover {
      text-decoration: underline;
    }
  }
  .user-menu {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    cursor: pointer;
    padding: $layout-spacing-sm $layout-spacing;
    .tile-content {
      line-height: normal;
    }
    .tile-action {
      padding-left: $layout-spacing-sm;
    }
    .user-name {
      font-weight: 500;
      margin-bottom: $border-width;
    }
    .user-branch {
      color: $gray-color-dark;
      font-size: $font-size-xs;
    }
  }
  .user-menu-list {
    position: absolute;
    top: $header-height;
    .menu-item {
      svg {
        $size: $font-size + $border-width;
        width: $size;
      }
      a {
        transition: all .15s ease;
        &:hover {
          color: $light-color;
        }
      }
    }
  }
  .main-nav {
    background-color: $light-color;
    box-shadow: 8px 1px 10px rgba(0, 0, 0, .2);
    height: $header-height;
    padding: 0 $layout-spacing-lg;
    z-index: $zindex-1;
    .avatar {
      background-color: $border-color;
      border-color: $border-width solid $gray-color;
      padding: $border-width;
    }
    .logo {
      height: 1.05rem;
    }
    .column {
      align-self: center;
    }
    .header-label {
      font-size: $font-size;
    }
    .header-title {
      color: $secondary-color;
      font-size: $font-size;
      font-weight: bold;
      margin-top: $layout-spacing-sm / 2;
    }
    .divider-vert {
      border-color: #D8D8D8;
      padding: 0 $layout-spacing;
      &::before {
        top: 0;
        bottom: 0;
        transform: translateX(0);
      }
    }
    .btn-menu-action {
      color: $gray-color-dark;
      height: auto;
      margin: 0 $layout-spacing;
      padding: 0;
      svg {
        font-size: 1rem;
        &.fa-search {
          font-size: .95rem;
        }
      }
      &:focus, &:active {
        box-shadow: none;
      }
      &.badge {
        color: $info-color;
        &::after {
          animation: blink-animation 1.2s infinite;
          background-color: $warning-color;
          height: $layout-spacing;
          right: 2px;
          top: 4px;
          width: $layout-spacing;
        }
      }
    }
  }
  .notification-bar {
    align-items: center;
    background-color: $red-color-500;
    color: $light-color;
    display: flex;
    font-weight: 600;
    height: $notification-bar-height;
    justify-content: center;
    padding: 0 $layout-spacing-lg;
  }
  .primary {
    display: grid;
    min-height: 1px;
    .main {
      flex: 1;
      overflow-y: auto;
      & > .forbidden-state {
        padding: $layout-spacing-lg;
      }
    }
  }
}

#branch-modal {
  .modal-container {
    height: 100%;
  }
  .modal-footer {
    display: none;
  }
  .modal-body {
    padding: $layout-spacing * 2 0;
  }
  .branches {
    .active {
      .avatar {
        background-color: $primary-color;
      }
    }
  }
  .tile {
    background-color: $bg-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    &:last-child {
      margin-bottom: 0;
    }
    .tile-icon {
      margin: 0 $layout-spacing-sm;
    }
  }
  .avatar {
    background-color: $gray-color;
    border: $border-width solid $border-color;
    height: 1.8rem;
    width: 1.8rem;
  }
  .tile-title {
    font-size: $font-size-lg;
    line-height: normal;
  }
  .tile-subtitle {
    color: $gray-color;
    font-size: $font-size-sm;
    line-height: normal;
    margin-top: $layout-spacing-sm;
  }
}
</style>
