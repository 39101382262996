export const LIST_APPOINTMENTS = 'clinical:listAppointments';
export const CREATE_APPOINTMENT = 'clinical:createAppointment';
export const UPDATE_APPOINTMENT = 'clinical:updateAppointment';
export const DELETE_APPOINTMENT = 'clinical:deleteAppointment';
export const BLOCK_APPOINTMENT = 'clinical:blockAppointment';
export const PRINT_APPOINTMENT_LIST = 'clinical:printAppointmentList';

export default [
  {
    action: LIST_APPOINTMENTS,
    name: 'Agendamentos: Listar',
  },
  {
    action: CREATE_APPOINTMENT,
    name: 'Agendamentos: Criar',
  },
  {
    action: UPDATE_APPOINTMENT,
    name: 'Agendamentos: Atualizar',
  },
  {
    action: DELETE_APPOINTMENT,
    name: 'Agendamentos: Excluir',
  },
  {
    action: BLOCK_APPOINTMENT,
    name: 'Agendamentos: Bloquear',
  },
  {
    action: PRINT_APPOINTMENT_LIST,
    name: 'Agendamentos: Imprimir lista',
  },
];
