<template>
  <dx-modal id="expense-modal" title="Consulta procedimentos"
            :value="show" @input="close" size="lg">
    <div class="columns form-group">
      <div class="column col-5 form-group">
        <label for="insurance" class="form-label">Convênio</label>
        <select id="insurance" class="form-select"
                v-model="filters.insurancePlanId"
                @change="findExpenses">
          <option value="">[Todos os convênios]</option>
          <option v-for="(item, i) in insurances" :key="i"
                  :value="item.plan.id">{{ item.customName }}</option>
        </select>
      </div>
      <div class="column form-group">
        <label for="search" class="form-label">Nome do procedimento</label>
        <input type="text" id="search" class="form-input"
               v-model="filters.search"
               @keypress.enter="findExpenses"
               placeholder="Consulta em consultório...">
      </div>
      <div class="column col-auto form-group" style="align-self: flex-end">
        <button class="btn btn-primary btn-icon btn-icon-left mb-2"
                :class="{loading: loading}"
                :disabled="loading"
                @click="findExpenses">
          <fa-icon :icon="['fal', 'search']"/>Pesquisar
        </button>
      </div>
    </div>
    <div class="empty mt-2" v-if="items.length === 0">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
      </div>
      <p class="empty-title h5">Procedimentos</p>
      <p class="empty-subtitle">
        Nenhum procedimento encontrado. Verifique os filtros para realizar novas buscas
      </p>
    </div>
    <table class="table" v-else>
      <thead>
      <tr>
        <th>Código</th>
        <th>Nome</th>
        <th>Convênio</th>
        <th class="text-right">Total</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in items" :key="i">
        <td>{{ item.code | tuss }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.insurance.name }}</td>
        <td class="text-right">
          <span v-if="item.value > 0">{{ item.value | currency }}</span>
          <fa-icon class="text-error" :icon="['fal', 'lock']" v-else />
        </td>
      </tr>
      </tbody>
    </table>
    <template slot="footer">
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      filters: {
        insurancePlanId: '',
        search: '',
      },
      insurances: [],
      items: [],
    };
  },
  mounted() {
    this.loadInsurances();
    this.findExpenses();
  },
  methods: {
    findExpenses() {
      this.loading = true;
      const params = {
        limit: 40,
      };

      if (this.filters.insurancePlanId) {
        params.insurancePlanId = this.filters.insurancePlanId;
      }

      if (this.filters.search) {
        params.search = this.filters.search;
      }

      return this.$http.get('/expense-pricing', { params })
        .then(({ data }) => {
          this.items = data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">

</style>
