import Vue from 'vue';

export default {
  newChatChannel({ state, dispatch }, data) {
    const memberIds = data.members
      .filter(({ id }) => id !== state.user.id)
      .map(({ id }) => id);

    const chat = state.chat.chats.find(({ members }) => (
      members.some(({ id }) => memberIds.includes(id))
    ));

    if (chat) {
      chat.id = data.id;
      chat.members = data.members;

      if (data.lastMessage) {
        dispatch('newChatMessage', {
          ...data.lastMessage,
          channelId: data.id,
        });
      }
    }
  },
  newChatMessage({ state, getters, commit }, data) {
    const chat = state.chat.chats.find(({ id }) => data.channelId === id);

    if (chat) {
      if (data.authorId !== state.auth.user.id) {
        if (!getters.chatCurrent || getters.chatCurrent.id !== chat.id) {
          chat.unread = true;
        }
        if (!state.chat.show) {
          const member = chat.members.find(({ id }) => id === data.authorId);
          Vue.notification.show(member.name, {
            timeout: 10000,
            type: 'info',
            content: 'Nova mensagem',
            click: (close) => {
              commit('Chat.SET_CURRENT', chat);
              state.chat.show = true;
              close();
            },
          });
        }
      }

      chat.lastMessage = {
        text: data.text,
        authorId: data.authorId,
        date: data.date,
      };
    }
  },
  resetChat({ commit }) {
    commit('Chat.SHOW', false);
    commit('Chat.SET_CHATS', []);
  },
};
