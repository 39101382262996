import {
  LIST_PERMISSION_GROUPS,
  CREATE_PERMISSION_GROUP,
  UPDATE_PERMISSION_GROUP,
} from '@/data/actions/modules/general';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';

export default [
  {
    path: '/login',
    component: Login,
    meta: {
      layout: null,
      requiresAuth: false,
    },
  }, {
    path: '/logout',
    component: Logout,
    meta: {
      layout: null,
      requiresAuth: false,
    },
  }, {
    path: '/permission-groups',
    component: () => import(/* webpackChunkName: "permissions" */ './components/permission/GroupList.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PERMISSION_GROUPS,
    },
  }, {
    path: '/permission-groups/create',
    component: () => import(/* webpackChunkName: "permissions" */ './components/permission/GroupForm.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PERMISSION_GROUP,
    },
  }, {
    path: '/permission-groups/:id',
    component: () => import(/* webpackChunkName: "permissions" */ './components/permission/GroupForm.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PERMISSION_GROUPS,
    },
  }, {
    path: '/permission-groups/:id/edit',
    component: () => import(/* webpackChunkName: "permissions" */ './components/permission/GroupForm.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PERMISSION_GROUP,
    },
  },
];
