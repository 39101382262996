import { routes as appointment } from './appointment';
import { routes as auth } from './auth';
import { routes as me } from './me';
// import { routes as patient } from './patient';

export default [
  ...appointment,
  ...auth,
  ...me,
  // ...patient,
];
