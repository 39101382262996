import moment from 'moment';
import { modules } from '@/data/modules';

export const expiresInDays = ({ user }) => {
  if (user && user.branch && user.branch.expiresAt) {
    return moment(user.branch.expiresAt)
      .diff(moment().startOf('day'), 'days');
  }
  return Infinity;
};

export const branchIsExpired = (state, getters) => (
  getters.expiresInDays < 0
);

export const expiresNotification = (state, getters) => {
  if (getters.expiresInDays <= 30) {
    if (getters.branchIsExpired) {
      return 'O sistema está suspenso. Entre em contato com o administrador.';
    }

    let period = `em ${getters.expiresInDays} dias`;
    if (getters.expiresInDays === 1) {
      period = 'amanhã';
    } else if (getters.expiresInDays === 0) {
      period = 'hoje';
    }
    return `O sistema será suspenso ${period}. Entre em contato com o administrador.`;
  }
  return null;
};

export const branchSettings = ({ user }) => (
  user && user.branch ? user.branch.settings : null
);

export const hasRelationship = state => (rel, strict = false) => {
  if (!state.user || !state.user.branch || !Array.isArray(state.user.branch.rel)) {
    return false;
  }

  const has = state.user.branch.rel.includes(rel);
  return strict ? has && state.user.branch.rel.length === 1 : has;
};

export const isProfessional = (state, getters) => (
  getters.hasRelationship('professional')
);

export const isCollaborator = (state, getters) => (
  getters.hasRelationship('collaborator')
);

export const isProfessionalOnly = (state, getters) => (
  getters.hasRelationship('professional', true)
);

export const isCollaboratorOnly = (state, getters) => (
  getters.hasRelationship('collaborator', true)
);

export const currentModule = (state) => {
  const module = state.module || 'clinical';
  return modules.find(item => item.key === module);
};

export const hasModule = ({ user }) => (name) => {
  if (user && user.branch && user.branch.modules) {
    return user.branch.modules.includes(name);
  }
  return false;
};
