import localforage from 'localforage';
import { http } from 'src/plugins/http';
import { update } from 'src/permission';

export const attemptLogin = ({ dispatch }, payload) => (
  http.post('/auth', payload)
    .then(({ data }) => {
      const { user, token } = data;

      dispatch('setUser', user);
      dispatch('setToken', token);
      dispatch('setModule', 'clinical');

      return user;
    })
);

export const logout = ({ dispatch }) => (
  Promise.all([
    dispatch('setUser', null),
    dispatch('setToken', null),
    dispatch('setModule', null),
  ])
);

export const setUser = ({ commit }, user) => {
  commit('Auth.SET_USER', user);
  update((
    user && user.branch && Array.isArray(user.branch.actions)
      ? user.branch.actions
      : []
  ));
  if (user && user.branch) {
    if (!user.branch.settings) {
      user.branch.settings = {};
    }
    if (!('clinical.blockServiceWithoutAccount' in user.branch.settings)) {
      user.branch.settings['clinical.blockServiceWithoutAccount'] = false;
    }
  }
  return Promise.resolve(user);
};

export const setToken = ({ commit }, token) => {
  commit('Auth.SET_TOKEN', token);
  return Promise.resolve(token);
};

export const setModule = ({ commit }, module) => {
  commit('Auth.SET_MODULE', module);
  return Promise.resolve(module);
};

export const checkUserToken = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }

  return Promise.all([
    localforage.getItem('token'),
    localforage.getItem('module').catch(() => 'clinical'),
  ])
    .then(([token, module]) => {
      if (!token) {
        return Promise.reject(new Error('NO_TOKEN'));
      }

      dispatch('setModule', module);

      return dispatch('setToken', token);
    })
    .then(() => dispatch('loadUser'));
};

export const loadUser = ({ dispatch }) => (
  http.get('/me')
    .then(({ data }) => dispatch('setUser', data))
    .catch(() => {
      dispatch('logout');
      return Promise.reject(new Error('FAIL_IN_LOAD_USER'));
    })
);
