export const modules = [
  {
    key: 'clinical',
    name: 'Clínico',
    icon: ['fal', 'clinic-medical'],
    url: '/',
    external: false,
  },
];

export function getModule(key) {
  return modules.find(item => item.key === key) || null;
}

export default modules;
