<template>
  <div class="login-page">
    <div class="logo-stenci">
      <img src="../../../assets/svg/logo-stenci.svg" alt="stenci"/>
    </div>
    <div class="login-element hide-md">
      <div class="login-image" />
    </div>
    <div class="login-form">
      <form @submit.stop.prevent="submit">
        <h2>Faça seu login</h2>
        <span>Insira seus dados abaixo</span>
        <div class="form-body">
          <div class="form-group">
            <label class="form-label" for="username">Usuário</label>
            <input v-model.trim="form.username" id="username"
                   class="form-input input-lg" placeholder="Email, CPF ou CNPJ"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="password">Senha</label>
            <input v-model.trim="form.password" type="password"
                   id="password" class="form-input input-lg" placeholder="Senha"/>
          </div>
          <label class="form-switch">
            <input type="checkbox" v-model="form.remember"/>
            <i class="form-icon"/> Manter-me logado
          </label>
        </div>
        <button class="btn btn-lg btn-primary btn-block" :class="{ loading }">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
        remember: true,
      },
      loading: false,
    };
  },
  mounted() {
    localforage.getItem('login')
      .then((login) => {
        const data = login || {};
        this.form.remember = data.remember;
        this.form.username = data.username;
        document.querySelector(`#${data.username ? 'password' : 'username'}`).focus();
      });
  },
  methods: {
    ...mapActions(['attemptLogin', 'loadUser']),
    submit(e) {
      this.loading = true;

      localforage.setItem('login', {
        username: this.form.remember ? this.form.username : '',
        remember: this.form.remember,
      });

      this.$toast.close();

      const { username, password } = this.form;

      this.attemptLogin({ username, password })
        .then(() => this.$router.push({ path: '/home' }))
        .catch(() => this.$toast.show('Dados de login inválidos', { type: 'error' }))
        .then(() => {
          this.loading = false;
        });

      e.preventDefault();
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/_variables.scss';

$bg-elements: '../../../assets/images/login-elements.png';

.login-page {
  background-color: #fafafa;
  display: flex;
  height: 100vh;
  .logo-stenci {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    img {
      width: 7rem;
    }
  }
  .login-element {
    display: flex;
    justify-content: center;
    width: 100%;
    .login-image {
      background: url($bg-elements) no-repeat center;
      background-size: contain;
      max-width: 30rem;
      width: 70%;
    }
  }
  .login-form {
    align-items: center;
    background-color: $light-color;
    border-left: $border-color solid $border-width;
    display: flex;
    justify-content: center;
    flex: 0 0 22rem;
    @media screen and (max-width: $size-md) {
      flex: 0 0 100%;
    }
    .form-body {
      margin: 2rem 0;
      width: 15rem;
    }
  }
}
</style>
