// export const LIST_PATIENTS = 'general:listPatients';
// export const CREATE_PATIENT = 'general:createPatient';
// export const UPDATE_PATIENT = 'general:updatePatient';
// export const DELETE_PATIENT = 'general:deletePatient';
// export const PRINT_PATIENT_LIST = 'general:printPatientList';
// export const PRINT_PATIENT_ALERT = 'general:printPatientAlert';

export const LIST_PERMISSION_GROUPS = 'general:listPermissionGroups';
export const CREATE_PERMISSION_GROUP = 'general:createPermissionGroup';
export const UPDATE_PERMISSION_GROUP = 'general:updatePermissionGroup';
export const DELETE_PERMISSION_GROUP = 'general:deletePermissionGroup';
export const PRINT_PERMISSION_GROUP_LIST = 'general:printPermissionGroupList';

export default [
  // {
  //   action: LIST_PATIENTS,
  //   name: 'Pacientes: Listar',
  // },
  // {
  //   action: CREATE_PATIENT,
  //   name: 'Pacientes: Criar',
  // },
  // {
  //   action: UPDATE_PATIENT,
  //   name: 'Pacientes: Atualizar',
  // },
  // {
  //   action: DELETE_PATIENT,
  //   name: 'Pacientes: Excluir',
  // },
  // {
  //   action: PRINT_PATIENT_LIST,
  //   name: 'Pacientes: Imprimir lista',
  // },
  // {
  //   action: PRINT_PATIENT_ALERT,
  //   name: 'Pacientes: Imprimir lista de alertas',
  // },
  {
    action: LIST_PERMISSION_GROUPS,
    name: 'Grupos de permissões: Listar',
  },
  {
    action: CREATE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Criar',
  },
  {
    action: UPDATE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Atualizar',
  },
  {
    action: DELETE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Excluir',
  },
  {
    action: PRINT_PERMISSION_GROUP_LIST,
    name: 'Grupos de permissões: Imprimir lista',
  },
];
