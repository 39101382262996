<template>
  <div class="panel chat-window">
    <div class="panel-header">
      <button class="btn btn-action btn-icon" @click="back">
        <fa-icon :icon="['fal', 'chevron-left']" />
      </button>
      <div class="panel-title text-ellipsis">{{ member.name }}</div>
      <button class="btn btn-action btn-icon" @click="close">
        <fa-icon :icon="['fal', 'times']" />
      </button>
    </div>

    <div class="panel-body" :class="loading ? 'loading loading-lg' : ''">
      <message-item v-for="(item, i) in messages" :item="item" :key="i" />
      <div ref="scrollRef"></div>
    </div>

    <div class="panel-footer">
      <user-input v-model="message" @send="sendMessage" :sending="sending" />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';
import MessageItem from '@/components/chat/window/MessageItem.vue';
import UserInput from '@/components/chat/window/Input.vue';
import AblyService from '@/services/ably';
import moment from 'moment';

export default {
  components: {
    MessageItem,
    UserInput,
  },
  data() {
    return {
      message: '',
      messages: [],
      loading: false,
      sending: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
      chats: ({ chat }) => chat.chats,
    }),
    ...mapGetters({
      current: 'chatCurrent',
      encodeChannel: 'encodeChannel',
    }),
    member() {
      return this.current.members.find(({ id }) => id !== this.user.id);
    },
  },
  watch: {
    'current.lastMessage': function lastMessage(message) {
      this.messages.push(message);
      this.scrollToEnd();
    },
  },
  methods: {
    ...mapActions({
      newChannel: 'newChatChannel',
      newMessage: 'newChatMessage',
    }),
    ...mapMutations({
      hide: 'Chat.HIDE',
      setChats: 'Chat.SET_CHATS',
      setCurrent: 'Chat.SET_CURRENT',
    }),
    init() {
      if (this.current.id) {
        this.loading = true;
        this.$http.get(`/chat/chats/${this.current.id}/messages`)
          .then(({ data: { items } }) => {
            this.messages = items;
            this.scrollToEnd('instant');
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async sendMessage() {
      if (!this.message.trim() || this.sending) {
        return;
      }

      this.sending = true;

      const formData = {
        text: this.message.trim(),
        userId: this.member.id,
        channelId: this.current.id,
      };

      try {
        if (this.current.id) {
          this.sendMessageEvent(formData);
        }

        const { data } = await this.$http.post('/chat/chats', formData);
        this.message = '';

        if (!this.current.id && data.channel) {
          this.sendChannelEvent(data);
        }
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.sending = false;
      }
    },
    sendMessageEvent(data) {
      const { realtime } = AblyService.instance(this.user.id);

      const messageData = {
        ...data,
        authorId: this.user.id,
        date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      };

      // Send message locally
      this.newMessage(messageData);

      // Send message to other member
      return realtime.channels
        .get(this.encodeChannel(data.userId))
        .publish('new_message', messageData);
    },
    sendChannelEvent({ channel, message }) {
      const { realtime } = AblyService.instance(this.user.id);

      const data = {
        ...channel,
        lastMessage: message,
      };

      // Send message locally
      this.newChannel(data);

      // Send message to other member
      return realtime.channels
        .get(this.encodeChannel(this.member.id))
        .publish('new_channel', data);
    },
    close() {
      this.setCurrent(null);
      this.hide();
    },
    back() {
      this.setCurrent(null);
    },
    scrollToEnd(behavior = 'smooth') {
      this.$nextTick(() => {
        this.$refs.scrollRef.scrollIntoView({ block: 'end', behavior });
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.chat-window.panel {
  .panel-body {
    .chat-message-item {
      padding-bottom: $layout-spacing-lg;
    }
  }

  .panel-footer {
    border-top: $border-width solid $border-color;
    .form-group {
      flex: 0 0 auto;
      .form-input {
        height: 2rem;
      }
    }
  }
}
</style>
