import axios from 'axios';
import { apiUrl } from 'src/config';
import interceptors from './interceptors';

export const http = axios.create({
  baseURL: apiUrl,
  timeout: 90000,
});

export function setToken(token) {
  http.defaults.headers.common.Authorization = `JWT ${token}`;
}

export default function install(Vue, { router }) {
  interceptors(http, router);

  /* eslint-disable no-param-reassign */
  Vue.http = http;

  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return http;
    },
  });
}
